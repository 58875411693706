import axios from 'axios'

const API = '/encuestas'

const Encuestas = {
    listar(params){
      return axios(`${API}/listar-web`,{params})
    },
    mostrar(id){
      return axios(`${API}/mostrar/${id}`)
    },
    listarPreguntas(id){
      return axios(`${API}/listar-preguntas/${id}`)
    },
    guardar(payload){
      return axios.post(`${API}`,payload)
    },
    guardarFull(payload){
      return axios.post(`${API}/guardar-full`,payload)
    },
    adicionarDestinatario(params){
      return axios.post(`${API}/destinatario`,params)
    },
    cambiarEstadoEncuesta(params){
      return axios.put(`${API}/estado`,params)
    },
    editarFechaVigencia(params){
      return axios.put(`${API}/vigencia`,params)
    },
    eliminarPregunta(params){
      return axios.delete(`${API}/eliminar-pregunta`,{params})
    },
    eliminarOpcion(params){
      return axios.delete(`${API}/eliminar-opcion`,{params})
    },
    eliminarDestinatario(params){
      return axios.delete(`${API}/remover-destinatario`,{params})
    },
    eliminarEncuesta(params){
      return axios.delete(`${API}/eliminar`,{params})
    },
    eliminarRespuestas(params){
      return axios.delete(`${API}/eliminar-respuestas`,{params})
    }
}

export default Encuestas
