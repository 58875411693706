<template>
    <section class="encuesta-ver w-100 pt-4 pl-4 pl-md-5" v-loading="loading">
        <!-- breadcrumb -->
        <div class="d-flex gap-4 flex-wrap justify-content-between aling-items-center mt-3 mb-5 w-100">
            <div class="mx-0">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ name: 'encuestas.main' }">
                        <span class="f-400 f-15">Encuestas</span>
                    </el-breadcrumb-item >
                    <el-breadcrumb-item>
                        <span class="text-black f-15">{{ encuesta.nombre }}</span>
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="mr-2">
                <div class="d-flex">
                    <div class="my-auto">
                        <p class="f-17 text-center">{{encuesta.res_porcentaje}}% Encuestados</p>
                        <div class="porcentaje-outer">
                            <div class="porcentaje-inner" :style="'width:'+encuesta.res_porcentaje+'%'" />
                        </div>
                    </div>
                    <div v-show="encuesta.estado == 1" class="estado-encuesta activa d-middle-center my-auto mx-3">
                        Activa
                    </div>
                    <div v-show="encuesta.estado == 0" class="estado-encuesta inactiva d-middle-center my-auto mx-3">
                        Inactiva
                    </div>
                    <div v-show="encuesta.estado == 2" class="estado-encuesta finalizado d-middle-center my-auto mx-3">
                        Finalizada
                    </div>
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                        <div slot="content" class="tooltip-editar-residente">
                            <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="$refs.modalEliminarRespuestas.toggle()">
                                <p class="f-15">Eliminar respuestas</p>
                            </div>
                            <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="modalFinalizarEncuesta">
                                <p v-if="encuesta.estado != 2" class="f-15">Finalizar</p>
                            </div>
                            <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="modalEliminarEncuesta">
                                <p class="f-15">Eliminar</p>
                            </div>
                        </div>
                        <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer">
                            <i class="icon-vertical-points" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="row mt-4 w-100">
            <el-tabs type="card" class="tabs-crear-encuesta w-100" >
                <el-tab-pane label="Información">
                    <p class="col-12 f-15">{{encuesta.descripcion}}</p>
                    <div class="row mx-0 my-4">
                        <div class="col-auto">
                            <p class="f-500 f-17">
                                Vigencia:
                                <span v-if="encuesta.fecha_inicio" class="f-300 f-15">{{encuesta.fecha_inicio | helper-fecha('DD MMM YYYY')}} - {{encuesta.fecha_fin | helper-fecha('DD MMM YYYY')}}</span>
                                <i v-if="encuesta.estado != 2" class="icon-pencil-outline ml-1 cr-pointer" @click="cambiarFechas" />
                            </p>
                        </div>
                        <div class="col-auto">
                             <div v-if="encuesta.estado != 2" class="d-middle-center">
                                <p class="f-15">Se aceptan respuestas</p>
                                <el-switch v-model="encuesta.estado" :active-value="1" :inactive-value="0" active-color="#00D9B5" inactive-color="#E6E6E6" class="mx-2" @change="cambiarEstadoEncuesta"/>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mb-2">
                        <div class="col-auto">
                            <div class="d-middle">
                                <p class="f-17 f-500">Destinatarios</p>
                                <i v-if="encuesta.estado != 2" class="icon-pencil-outline ml-1 cr-pointer" @click="editarDestinatarios" />
                            </div>
                        </div>
                    </div>
                    <div class="row mx-auto">
                        <div class="col-12 col-sm-7 col-md-5 col-lg-4 col-xl-3">
                            <p v-for="(destinatario,i) in encuesta.destinatarios" :key="i" class="mb-2 br-20 bg-f5 px-2 py-1 border f-14">{{destinatario.nombre}} </p>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane>
                    <span slot="label" class="d-middle-center" @click="listarPreguntas"> 
                        Respuestas <div class="f-11 n-preguntas d-middle-center ml-2">{{encuesta.cant_respuestas}}</div> 
                    </span>
                      <div v-for="(pregunta,i) in preguntas" :key="i">

                        <!-- respuesta abierta -->
                        <div v-if="pregunta.tipo==1" class="row mx-0" >
                          <p class="col-12 f-15 mb-2 f-500">{{pregunta.titulo}}</p>
                          <div class="col-12 col-lg-10 col-md-12 col-sm-12 col-xl-8 my-2 my-2">
                              <div class="row mb-2">
                                  <div class="col-auto">
                                      <p>{{pregunta.respuestas.length}} Respuestas</p>
                                  </div>
                                  <div class="col-auto">
                                      <p class="d-flex">
                                          <i class="icon-pencil-outline mr-1" />
                                          {{tipoPregunta(pregunta.tipo)}}
                                      </p>
                                  </div>
                              </div>
                              <!-- listado -->
                              <div v-for="(respuesta,i) in pregunta.respuestas" :key="i" class="row bg-f5 mb-2 br-4 py-2 mx-0">
                                  <div class="col-12 col-sm-12 col-md-8 my-auto my-auto">
                                      <p class="f-15">{{respuesta.texto}}</p>
                                  </div>
                                  <div class="col-12 col-sm-12 col-md-4 my-auto my-auto">
                                      <div class="d-flex">
                                          <i class="icon-account-outline my-auto mr-1 f-500 f-22" />
                                          <div class="f-12">
                                              <p>{{respuesta.usuario.nombre}}</p>
                                              <p>{{respuesta.usuario.vivienda}}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>

                        <!-- seleccion unica  -->
                        <div v-if="pregunta.tipo==2" class="row mx-0" >
                          <p class="col-12 f-15 mb-2 f-500">{{pregunta.titulo}}</p>
                          <div class="col-12 col-lg-8 my-2">
                              <div class="row mb-2">
                                  <div class="col-auto">
                                      <p>{{pregunta.respuestas.length}} Respuestas</p>
                                  </div>
                                  <div class="col-auto">
                                      <p class="d-flex">
                                          <i class="icon-radio_button_off mr-1" />
                                          {{tipoPregunta(pregunta.tipo)}}
                                      </p>
                                  </div>
                              </div>
                              <div class="row mx-0">
                                  <div class="col-auto">
                                      <echart :ref="'echart_'+pregunta.id" :options="chartSeleccion" width="500px" />
                                  </div>
                              </div>
                          </div>
                        </div>

                        <!-- respuesta multiple  -->
                        <div v-if="pregunta.tipo==3" class="row mx-0" >
                          <p class="col-12 f-15 mb-2 f-500">{{pregunta.titulo}}</p>
                          <div class="col-8 my-2">
                              <div class="row mb-2">
                                  <div class="col-auto">
                                      <p>{{pregunta.respuestas.length}} Respuestas</p>
                                  </div>
                                  <div class="col-auto">
                                      <p class="d-flex">
                                          <i class="icon-hamburguer mr-1" />
                                          {{tipoPregunta(pregunta.tipo)}}
                                      </p>
                                  </div>
                              </div>
                              <div class="row mx-0">
                                  <div class="col-auto">
                                      <echart :ref="'echart_'+pregunta.id" :options="chartSeleccion" width="500px" />
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    
                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- partials -->
        <modal ref="modalEliminarRespuestas" titulo="Eliminar respuestas" adicional="Eliminar" @adicional="eliminarRespuestas">
            <p class="text-center">¿Desea eliminar todas las respuestas de la encuesta? </p>
        </modal>
        <modal-eliminar ref="modalabrirEliminarEncuesta" titulo="Eliminar encuesta" mensaje="¿Desea eliminar esta encuesta? (Todos los datos hasta ahora recopilados por la encuesta también se borrarán.)" tamano="modal-sm" alignText="text-center" @eliminar="eliminarEncuesta"/>
        <modal-confirmar ref="modalabrirFinalizarEncuesta" titulo="Finalizar encuesta" mensaje="¿Desea finalizar esta encuesta?" tamano="modal-sm" adicional="Finalizar" @adicional="finalizarEncuesta" />
        <modal-cambiar-fechas ref="modalCambiarFechas" @actualizar="obtenerEncuesta"/>
        <modal-editar-destinatarios ref="modalEditarDestinatarios" @actualizar="actualizarDestinatarios"/>
    </section> 
</template>
<script>
import Encuestas from '~/services/encuestas'
export default {
    components:{
        modalCambiarFechas: () => import('./partials/modalCambiarFechas'),
        modalEditarDestinatarios: () => import('./partials/modalEditarDestinatarios')
    },
    data(){
        return{
            loading: false,
            activeName: 'first',
            switch1: false,
            estadoEncuesta:0,
            chartSeleccion:{
                
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: "vertical",
                    right: "0%",
                    top: "30%",
                    icon: 'circle'
                },
                series: [
                    {
                        type: 'pie',
                        center: ['30%', '50%'],
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 18, name: 'Respuesta1' },
                            { value: 23, name: 'Respuesta2' },
                            { value: 25, name: 'Respuesta3' },
                            { value: 43, name: 'Respuesta4 ' }
                        ],
                        color:['#2A5E83','#D16887', '#FF6980', '#FFB292']
                    }
                ]
            },
            encuesta: {
                id: null,
                estado: null,
                solo_grupo: null,
                fecha_inicio:'',
                fecha_fin:'',
            },
            preguntas: []            
        }
    },
    created(){
      this.obtenerEncuesta()
    },
    methods:{
        modalEliminarEncuesta(){
            this.$refs.modalabrirEliminarEncuesta.toggle()
        },
        modalFinalizarEncuesta(){
            this.$refs.modalabrirFinalizarEncuesta.toggle()
        },
        cambiarFechas(){
            let obj = {
                fecha_inicio : this.encuesta.fecha_inicio,
                fecha_fin: this.encuesta.fecha_fin
            }
            this.$refs.modalCambiarFechas.toggle(obj)
        },
        editarDestinatarios(){
            let siViviendaTieneSoloGrupo = this.encuesta.solo_grupo ? this.encuesta.destinatarios : []
            let encuesta = {
                cantidad_permitida: this.encuesta.cantidad_permitida,
                arrayGrupos: siViviendaTieneSoloGrupo
            }
            this.$refs.modalEditarDestinatarios.toggle(encuesta)
        },
        async actualizarDestinatarios(objeto){
            try {
                let params = {
                    id_encuesta: this.encuesta.id,
                    nombre: this.encuesta.nombre,
                    descripcion: this.encuesta.descripcion,
                    cantidad_permitida: objeto.cantidad_permitida,
                    solo_grupo: objeto.solo_grupo
                }

                const { data } = await Encuestas.guardar(params)

                this.notificacion('','Encuesta actualizada correctamente','success')

                this.obtenerEncuesta()
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async cambiarEstadoEncuesta(estado = this.encuesta.estado){
            try {
                let params = {
                    id_encuesta: this.encuesta.id,
                    estado: estado
                }
                const { data } = await Encuestas.cambiarEstadoEncuesta(params)

                this.notificacion('','Estado actualizado correctamente','success')
                this.obtenerEncuesta()
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async obtenerEncuesta(){
          try {
            this.loading = true
            const {data} = await Encuestas.mostrar(this.$route.params.id_encuesta)
            this.encuesta = data.data
            this.loading = false
          } catch (e){
              this.errorCatch(e)
              this.loading = false
          }
        },
        async listarPreguntas(){
          try {
            this.loading = true
            const {data} = await Encuestas.listarPreguntas(this.$route.params.id_encuesta)
            this.preguntas = data.data
            
            this.loading = false
            setTimeout(() => this.preguntasOpciones(this.preguntas), 100)
          } catch (e){
              this.errorCatch(e)
              this.loading = false
          }
        },
        async finalizarEncuesta(){
            try {
                await this.cambiarEstadoEncuesta(2)

                this.encuesta.estado = 2
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async eliminarEncuesta(){
            try {
                let id_encuesta = this.encuesta.id
                const { data } = await Encuestas.eliminarEncuesta({id_encuesta})
                
                this.$refs.modalabrirEliminarEncuesta.toggle()
                this.$router.replace({name: "encuestas.main"})
                this.notificacion('','Encuesta eliminada correctamente','success')
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async eliminarRespuestas(){
            try {
                let id_encuesta = this.encuesta.id
                const { data } = await Encuestas.eliminarRespuestas({id_encuesta})
                
                this.$refs.modalEliminarRespuestas.toggle()
                this.notificacion('','Respuestas de la encuesta eliminadas correctamente','success')
                this.$router.replace({name: "encuestas.crear.continuar", params: {id_encuesta: this.encuesta.id}})
            } catch (error) {
                this.errorCatch(error)
            }
        },
        tipoPregunta(tipo) {
          let pregunta_tipo = ""

          switch (tipo) {
            case 1:
              pregunta_tipo = "Respuesta Abierta"
              break;
            case 2:
              pregunta_tipo = "Selección única"
              break;
            case 3:
              pregunta_tipo = "Selección múltiple"
              break;
          }
          return pregunta_tipo
        },
        preguntasOpciones(preguntas){          
          preguntas.forEach(pregunta => {
            if(pregunta.tipo == 2 || pregunta.tipo==3){
              let key = 'echart_'+pregunta.id
              
              if(typeof this.$refs[key] != 'undefined'){
                let opciones = []
                pregunta.opciones.forEach(opcion =>   {                  
                  let temp = {
                    id: opcion.id,
                    value: 0,
                    name: opcion.texto
                  }
                  opciones.push(temp)
                });

                if(pregunta.respuestas_count > 0){
                  pregunta.respuestas.forEach(respuesta => {
                    opciones.forEach(opcion => {
                      if(opcion.id == respuesta.id_opcion){
                        opcion.value += 1
                      }
                    });
                  });
                }
                
                let chartSeleccion={
                
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: "vertical",
                        right: "0%",
                        top: "30%",
                        icon: 'circle'
                    },
                    series: [
                        {
                            type: 'pie',
                            center: ['30%', '50%'],
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '20',
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: opciones,
                        }
                    ]
                }
                this.$refs[key][0].setOption(chartSeleccion)
              }
            }
          });
        }
    }
}
</script>
<style lang="scss" scoped>
.encuesta-ver{
    .btn-general{
        min-width: 86px;
    }
    .n-preguntas{
        background: #000;
        color: #fff;
        width: 17px;
        min-width: 17px;
        height: 17px;
        border-radius: 50%;
    }
    .text-verde{
        color: #109881;
    }
    .estado-encuesta{
        width: 77px;
        height: 22px;
        font-size: 12px;
        font-weight: 300;
        border-radius: 20px;
        &.finalizado{
            background: #000000;
            color: #fff;
        }
        &.inactiva{
            background: #DBDBDB;
        }
        &.activa{
            background: #93EDCF;
        }
    }
    .porcentaje{
        &-outer{
            min-width: 200px;
            height: 6px;
            border-radius: 3px;
            background: #DBDBDB;
        }
        &-inner{
            height: 6px;
            border-radius: 3px;
            background: var(--color-general);
        }
        
    }
}
</style>